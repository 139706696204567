<template>
  <div class="contactUs">
    <div class="banner" ref="contactUs">
      <div class="text">RELIABLE AND RESPONSIBLE</div>
    </div>
    <div class="brand">
      <div class="itemBox">
        <div class="item">
          <div class="title">Brand Story:</div>
          <div class="text">
            Empowering retail enterprises to transform and upgrade
          </div>
        </div>
        <div class="item">
          <div class="title">Brand Vision:</div>
          <div class="text">
            Reinventing the cash registers with AI technology
          </div>
        </div>
        <div class="item">
          <div class="title">Brand Philosophy:</div>
          <div class="text">Let AI truly enter people's lives</div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="text">
        COMPAX is a start-up company dedicated to the development of POS
        terminals, with a focus on providing high-quality products and services
        for global businesses in retail, hospitality, and other related
        industries. Our product line includes POS terminals running on both
        Android and Windows operating systems, integrating superior design,
        performance, and competitive pricing to meet diverse customer needs. Our
        mission at COMPAX is to export high-quality products from China to the
        world, providing robust support for crucial business processes such as
        payment and settlement for our global clients.
        <br />
        <br />
        With a team of experienced professionals, we are committed to becoming a
        leading provider of POS terminals, delivering greater value to our
        customers. Our expertise in hardware design, software development,
        manufacturing, and customer service ensures that we continuously
        introduce advanced technologies and innovative concepts to maintain our
        industry-leading position.
        <br />
        <br />
        At COMPAX, we strive to offer high-performance and reliable POS
        solutions, emphasizing user experience and design aesthetics to meet the
        demands for quality, usability, and practicality. We believe that
        through relentless effort and sustained innovation, COMPAX will emerge
        as a global leader in POS terminal solutions, creating significant value
        for our customers.
      </div>
    </div>
    <div class="map"></div>
    <div class="imgInfo">
      <div class="content w"></div>
    </div>
    <div class="contactUsContainer" ref="Inquiry">
      <div class="contactUsc w">
        <div class="title">Contact us</div>
        <div class="centent">
          <div class="left">
            <div class="logo">
              <img src="../../../assets/img/logo.png" alt="" />
            </div>
            <div class="content">
              <!-- <div>邮箱： info@osai.cc</div> -->
              <div class="content_item">
                <div>E-mail：</div>
                <div>info@compax.cc</div>
              </div>
            </div>
          </div>
          <div class="right">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item prop="ct">
                <el-input
                  placeholder="Message Content (required)"
                  resize="none"
                  type="textarea"
                  v-model="form.ct"
                ></el-input>
              </el-form-item>
              <el-form-item prop="lxr">
                <el-input placeholder="Contact" v-model="form.lxr"></el-input>
              </el-form-item>
              <el-form-item prop="sj">
                <el-input placeholder="Phone" v-model="form.sj"></el-input>
              </el-form-item>
              <el-form-item prop="mail">
                <el-input
                  placeholder="Email Address (required)"
                  v-model="form.mail"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="bth">
                  <el-button
                    style="background: #005cff; color: #fff"
                    v-model="form.name"
                    @click="handleSubmit"
                    >Submit</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { submitInfo } from '@/api/yw/jhsc.js'
// 接收方页面的组件中
import { EventBus } from '@/utils/eventBus.js'
export default {
  data() {
    return {
      path: '',
      rules: {
        mail: [
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur'
          }
        ],
        ct: [
          {
            required: true,
            message: 'Please enter the message',
            trigger: 'blur'
          }
        ]
      },
      form: {
        mail: '',
        title: 'Inquiry of COMPAX products',
        ct: '',
        lxr: '',
        sj: ''
      }
    }
  },
  mounted() {
    this.path = this.$route.path
    EventBus.$on(this.path, data => {
      // 处理事件
      this.toLocal(data)
    })
  },
  methods: {
    toLocal(Id) {
      //锚点存在跳转
      if (Id) {
        const top1 = this.$refs[Id].getBoundingClientRect().top
        // 获取滚动条的位置
        const top2 =
          document.body.scrollTop || document.documentElement.scrollTop
        // 计算元素距视窗的实际高度
        const top = top1 + top2
        // 滚动到相应位置
        window.scrollTo(0, top - localStorage.getItem('header'))
      }
    },
    //点击提交按钮
    async handleSubmit() {
      await this.$refs.form.validate()
      let { mail, title, ct, lxr, sj } = this.form
      let form = {
        mail,
        title,
        ct: `From: ${lxr}<br>Tel: ${sj}<br>Message: ${ct}`,
        lxr,
        sj
      }
      await submitInfo(qs.stringify(form))
      this.$message.success('succeed')
      await this.$refs.form.resetFields()
    }
  },
  beforeDestroy() {
    EventBus.$off(this.path)
  }
}
</script>

<style lang="less" scoped>
.contactUs {
  background: rgba(0, 0, 0, 1);
  .banner {
    width: 100%;
    height: 1000px;
    background: url('../../../assets/Contact Us配图/第一页.png') no-repeat
      center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    .text {
      letter-spacing: 10px;
      line-height: 52.13px;
    }
  }
  .brand {
    height: 1080px;
    background: url('../../../assets/Contact Us配图/第二页.png') no-repeat
      center;
    background-size: cover;
    display: flex;
    justify-content: center;
    .itemBox {
      margin-top: 40px;
      width: 977px;
      display: flex;
      justify-content: space-between;
      height: fit-content;
      flex-flow: wrap;
      .item {
        margin-top: 40px;
        .title {
          font-size: 36px;
          line-height: 52.13px;
          color: rgba(0, 92, 255, 1);
          font-weight: 700;
        }
        .text {
          font-size: 18px;
          line-height: 26.06px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .info {
    height: 720px;
    background: url('../../../assets/Contact Us配图/第三页.png') no-repeat
      center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    .text {
      width: 1114px;
      text-align: justify;
      font-size: 20px;
      line-height: 28.96px;
    }
  }
  .map {
    height: 674.92px;
    background: #000000;
    background: url('../../../assets/Contact Us配图/第四页.png') no-repeat
      center;
    background-size: cover;
  }
  .imgInfo {
    background: #000000;
    .content {
      height: 674.92px;
      background: url('../../../assets/Contact Us配图/第五页.png') no-repeat
        center;
      background-size: cover;
    }
  }
  .contactUsContainer {
    background: url(../../../assets/关于我们/蒙版分组.png) no-repeat center;
    background-size: cover;
    .contactUsc {
      padding: 42px 0px 72px 51px;
      height: 700px;
      .title {
        color: #fff;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 52.13px;
      }
      .centent {
        margin-top: 103px;
        display: flex;
        justify-content: space-between;
        .left {
          .logo {
            display: flex;
            width: 207.13px;
            height: 40px;
            margin-bottom: 37px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .content {
            color: #808080;
            font-size: 16px;
            font-weight: 400;
            line-height: 23.17px;
            .content_item {
              display: flex;
              margin-bottom: 37.5px;
              .info {
                width: 420px;
              }
            }
          }
          .qrcode_box {
            padding-top: 26.5px;
            color: #808080;
            font-size: 14px;
            font-weight: 500;
            line-height: 20.27px;
            display: flex;

            .item {
              text-align: center;
              width: 93.23px;
              height: 117px;
              margin-right: 8.54px;
              img {
                width: 100%;
              }
              div {
              }
            }
          }
        }
        .right {
          width: 593px;
          height: 100%;
          .bth {
            width: 100%;
            display: flex;
            justify-content: end;
            .el-button {
              padding: 0px;
              font-size: 20px;
              border: none;
              height: 50px;
              width: 108px;
            }
          }
          .el-input {
            :deep(.el-input__inner) {
              background: rgba(27, 32, 48, 1);
              height: 40px;
            }
            .el-input-group__append {
              padding: 0px;
            }
          }
          .el-form-item {
            margin-bottom: 20px;
          }
          .el-textarea {
            height: 188px;
            :deep(.el-textarea__inner) {
              height: 188px;
              background: rgba(27, 32, 48, 1);
            }
          }
        }
      }
    }
  }
}
</style>
